import { AiOutlineArrowLeft } from "react-icons/ai";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import BreadCrumb from "../breadcrumb";
import { Helmet } from "react-helmet";
function Bedrijf(props) {
    let history = useHistory();
    const [bedrijf, setBedrijf] = useState({ interests: [] });

    var link = config.api + "/companies/" + props.BedrijfId;

    useEffect(() => {
        function bedrijfOphalen(getReq) {
            axios
                .get(getReq)
                .then((res) => {
                    setBedrijf(res.data);
                })
                .catch((err) => {
                    //console.log(err)
                });
        }

        bedrijfOphalen(link);
    }, [link]);

    let inter = {
        elektromech: "Elektromechanica",
        bouw: "Bouwkunde",
        chem: "Chemie",
        infor: "Informatica",
        eict: "Elektronica-ICT",
        auto: "Automatisering",
        land: "Landmeten",
        etech: "Elektrotechniek"
    };

    function renderBreadCrumb() {
        if (props.type === "bedrijf") {
            return (
                <BreadCrumb
                    prev={[
                        ["/bedrijf", "Home"],
                        ["/bedrijf/bedrijven", "Bedrijven"]
                    ]}
                    now={bedrijf.company_name}
                />
            );
        } else if (props.type === "student") {
            return (
                <BreadCrumb
                    prev={[
                        ["/student", "Home"],
                        ["/student/bedrijven", "Bedrijven"]
                    ]}
                    now={bedrijf.company_name}
                />
            );
        } else if (props.type === "paneel") {
            return (
                <BreadCrumb
                    prev={[
                        ["/paneel", "Paneel"],
                        ["/paneel/bedrijven", "Bedrijven"]
                    ]}
                    now={bedrijf.company_name}
                />
            );
        }
    }

    function renderBezoekWebsite() {
        if (bedrijf.company_url !== "") {
            return (
                <a target="_blank" rel="noreferrer" className="button button-s t-7 b-none border-gray-700-20 c-gray-700-70 fw-500 mr-b-7" href={bedrijf.company_url}>
                    Bezoek website
                </a>
            );
        } else {
            return <></>;
        }
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    const [jobs, setJobs] = useState([]);
    const [stages, setStages] = useState([]);

    useEffect(() => {
        const jobParams = new URLSearchParams();
        jobParams.append("type", "J");
        jobParams.append("owner", props.BedrijfId);
        jobParams.append("active", "True");
        axios.get(config.api + "/vacancies", { params: jobParams }).then((response) => {
            setJobs(response.data);
        });
        const stageParams = new URLSearchParams();
        stageParams.append("type", "I");
        stageParams.append("owner", props.BedrijfId);
        stageParams.append("active", "True");
        axios.get(config.api + "/vacancies", { params: stageParams }).then((response) => {
            setStages(response.data);
        });
    }, [props.BedrijfId]);

    return (
        <section className="mr-v-30">
            <Helmet>
                <title>{"HR - Bedrijf - " + bedrijf.company_name}</title>
            </Helmet>
            {bedrijf.active || claims?.recruitment_privilege ? (
                <>
                    <div className="container w-65 m-w-90">
                        {renderBreadCrumb()}
                        <div className="row mr-t-15">
                            <div className="col-12 t-center mr-b-5">
                                <img src={bedrijf.company_logo} alt={bedrijf.company_name} width="250px" />
                            </div>
                            <div className="col-3">
                                <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
                                    <AiOutlineArrowLeft />
                                </button>
                            </div>
                            <div className="col-6">
                                <h1 className="t-center t-20 c-gray-500">{bedrijf.company_name}</h1>
                            </div>
                            <div className="col-3"></div>
                        </div>
                        <br />
                        <div className="t-center tags">
                            {bedrijf.interests.map((interesse, index) => {
                                return (
                                    <span key={index} className="b-yellow-400 t-7 mr-r-2 lh-15 pd-2 pd-v-1 border-r-5 c-gray-500 fw-700 tag">
                                        {inter[interesse]}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                    <div className="container w-50 l-w-70 m-w-90 s-w-95 mr-t-3">
                        <div className="t-center">{renderBezoekWebsite()}</div>
                        <div className="box pd-5 lh-13 display-box ul-fix">
                            <div dangerouslySetInnerHTML={{ __html: bedrijf.company_long_desc }}></div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="container w-65 m-w-90 mr-t-12 c-gray-500 row">
                        <div className="col-6 m-col-12 pd-r-2 m-pd-r-0 m-mr-t-2">
                            <h2 className="t-center">Stages</h2>
                            {stages.length === 0 && <div>Geen stage plaatsen gevonden</div>}
                            {stages.map((stage) => {
                                return (
                                    <div key={stage.id} className="box pd-4 mr-t-3">
                                        <a className="link c-blue-400" href={config.base + "/student/stages/" + stage.title.replace(/\s+/g, "-").toLowerCase() + "/" + stage.id}>
                                            {stage.title}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="col-6 m-col-12 pd-l-2 m-mr-t-2 m-pd-l-0">
                            <h2 className="t-center">Jobs</h2>
                            {jobs.length === 0 && <div>Geen jobs gevonden</div>}
                            {jobs.map((job) => {
                                return (
                                    <div key={job.id} className="box pd-4 mr-t-3">
                                        <a className="link c-blue-400" href={config.base + "/student/jobs/" + job.title.replace(/\s+/g, "-").toLowerCase() + "/" + job.id}>
                                            {job.title}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            ) : (
                <div className="container w-65 m-w-90 mr-t-15">
                    <h2 className="c-red t-center">Dit bedrijf is momenteel niet actief</h2>
                </div>
            )}
        </section>
    );
}

export default Bedrijf;
